<template>
	<page>
		<module>
			<c-form ref="form" column="2">
				<c-title style="margin-bottom: 10px;">基础信息</c-title>
				<c-form-item label="模板名称" required>
					<c-input v-model="templateName" name="template_name"></c-input>
				</c-form-item>
				<!-- <c-form-item label="默认打印机" required>
					<div class="flex-center">
						<c-input v-model="printer" class="flex-grow" disabled></c-input>
						<c-button color="sys" style="margin-left: 10px;" @click="setPrinter">选择打印机</c-button>
					</div>
				</c-form-item> -->
				<c-form-item label="打印方向" required>
					<c-radio-group v-model="orient" name="print_direction">
						<c-radio value="1">纵向打印</c-radio>
						<c-radio value="2">横向打印</c-radio>
					</c-radio-group>
				</c-form-item>
				<c-form-item label="纸张大小" required>
					<div class="flex-center-cross">
						<span>宽：</span>
						<c-input v-model="pageWidth" name="paper_width" unit="mm" :clear="false"></c-input>
						<span style="margin-left: 20px;">高：</span>
						<c-input v-model="pageHeight" name="paper_height" unit="mm" :clear="false"></c-input>
					</div>
				</c-form-item>
			</c-form>
			
			<c-title>编辑模板</c-title>
			<div v-if="!error" class="editor flex">
				<div class="editor-lodop">
					<object ref="LODOP_OB" classid="clsid:2105C259-1E0C-4534-8141-A753534CB4CA" width="950" height="500">
						<param name="wmode" value="transparent">  
						<param name="Caption" value="单据">
						<param name="Border" value="1">
						<param name="Color" value="#C0C0C0">
						<embed ref="LODOP_EM" TYPE="application/x-print-lodop" width="950" height="500" wmode="transparent" PLUGINSPAGE="install_lodop.exe">
					</object>
				</div>
				<div class="editor-element flex-column">
					<h1>打印项目</h1>
					<div class="flex-grow" style="overflow: auto;">
						<dl v-for="(type, i) in elementData" :key="i">
							<dt>{{type.name}}</dt>
							<dd class="flex-wrap">
								<a v-for="(item, j) in type.list" :key="j" @click="setElement(item)">{{item.attribute_title}}</a>
							</dd>
						</dl>
					</div>
				</div>
			</div>
			
			<template slot="button">
				<c-button color="main" size="form" @click="submitHandle">保存</c-button>
			</template>
		</module>
	</page>
</template>

<script>
	import getLodop from '@/assets/script/lib/LodopFuncsForEdit.js'
	let LODOP = null;
	
	export default {
		name: 'template_edit',
		
		props: {
			id: String,
			type: String
		},
		
		data() {
			return {
				error: false,
				templateName: '',
				printer: '',
				orient: 1,
				ratio: 10,
				pageWidth: '210',
				pageHeight: '297',
				elementData: []
			}
		},
		
		computed: {
			pageConfig() {
				return [
					parseInt(this.orient),
					parseFloat(this.pageWidth) * this.ratio,
					parseFloat(this.pageHeight) * this.ratio,
					this.templateName
				]
			}
		},
		
		watch: {
			id() {
				this.getDetail();
			},
			
			pageConfig() {
				this.resetPageConfig();
			}
		},
		
		mounted() {
			setTimeout(() => {
				this.init();
				if (this.id) {
					this.getDetail();
				} else {
					this.getElementData(this.type);
				}
			}, 500)
		},
		
		methods: {
			init() {
				LODOP = getLodop(this.$refs.LODOP_OB, this.$refs.LODOP_EM, (title, message) => {
					this.$confirm({
						type: 'warn',
						title,
						message,
						buttonName: '我知道了',
						cancelButton: false
					});
					this.error = true;
				});
				if (LODOP) {
					this.resetPageConfig();
					LODOP.SET_SHOW_MODE('DESIGN_IN_BROWSE', 1);
					LODOP.PRINT_DESIGN();
				}
			},
			
			getDetail() {
				this.request({
					url: '/print/template/detail',
					data: {
						template_id: this.id,
					},
					success: data => {
						this.$refs.form.clear();
						this.$refs.form.set(data);
						this.getElementData(data.billtype_id);
						if (LODOP) {
							eval(data.template_content
								.replace(/\r?\n/g,'')
								.replace(/,"/g,",'")
								.replace(/",/g,"',")
								.replace(/\("/g,"('")
								.replace(/"\);/g,"');")
							);
							LODOP.SET_SHOW_MODE('DESIGN_IN_BROWSE', 1);
							LODOP.PRINT_DESIGN();
						}
					}
				})
			},
			
			resetPageConfig() {
				if (LODOP) {
					LODOP.SET_PRINT_PAGESIZE.apply(LODOP, this.pageConfig);
				}
			},
			
			getElementData(type) {
				this.request({
					url: '/billtype_attribute/list',
					data: {
						billtype_id: type
					},
					success: data => {
						this.elementData = [{
							name: '字段',
							type: 1
						}, {
							name: '表格（循环）',
							type: 2
						}, {
							name: '表格',
							type: 5
						}, {
							name: '一维码',
							type: 3
						}, {
							name: '二维码',
							type: 4
						}, {
							name: 'HTML',
							type: 6
						}, {
							name: '自定义HTML',
							type: 7
						}].map(item => {
							return {
								name: item.name,
								list: data.filter(temp => temp.type == item.type)
							}
						});
					}
				})
			},
			
			setPrinter() {
				this.printer = LODOP.GET_PRINTER_NAME(LODOP.SELECT_PRINTER());
			},
			
			setElement(data) {
				switch (data.type) {
				case 1:
					LODOP.ADD_PRINT_TEXTA(data.attribute_name, 95, 95, 75, 20, data.attribute_value);
					break;
					
				case 2:
					LODOP.ADD_PRINT_HTM(167, 30, 712, 451, data.attribute_value);
					break;
					
				case 3:
					LODOP.ADD_PRINT_BARCODEA(data.attribute_name, 165, 40, 80, 80, 'Code39', data.attribute_value);
					break;
				
				case 4:
					LODOP.ADD_PRINT_BARCODEA(data.attribute_name, 165, 40, 80, 80, 'QRCode', data.attribute_value);
					break;
				
				case 5:
					LODOP.ADD_PRINT_HTM(167, 30, 712, 451, data.attribute_value);
					break;
					
				case 6:
					LODOP.ADD_PRINT_HTM(167, 30, 712, 451, data.attribute_value);
					break;
				}
			},
			
			submitHandle() {
				this.$refs.form.submit({
					url: this.id ? '/print/template/edit' : '/print/template/add',
					rule: {
						template_name: '请填写模板名称',
						paper_width: '请填写纸张大小',
						paper_height: '请填写纸张大小'
					},
					dataFormatter: data => {
						data.id = this.id;
						data.billtype_id = this.type;
						data.template_content = LODOP.GET_VALUE('ProgramCodes', 0);
						return data;
					},
					success: data => {
						this.$message({
							message: '保存成功'
						});
					}
				})
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.editor {
		width: 1250px;
		border: 1px solid #ddd;
		margin: 10px 20px;
		
		&-lodop {
			z-index: 100;
			position: relative;
			width: 950px;
			height: 500px;
		}
		
		&-element {
			box-sizing: border-box;
			width: 300px;
			border-left: 1px solid #ddd;
			background: #F9F9F9;
			
			h1 {
				line-height: 50px;
				font-size: 20px;
				text-align: center;
				color: #fff;
				background: var(--theme-main);
			}
			
			dl {
				margin: 10px 15px;
				
				dt {
					line-height: 40px;
					font-size: 15px;
					font-weight: bold;
					color: var(--theme-main);
				}
				
				dd {
					a {
						margin-right: 10px;
						margin-bottom: 10px;
						color: #444;
						
						&:hover {
							text-decoration: underline;
							color: var(--theme-main);
						}
					}
				}
			}
		}
	}
</style>