var CreatedOKLodop7766 = null;

//====获取LODOP对象的主过程：====
function getLodop(oOBJECT, oEMBED, error) {
	var strHtmInstall =
		"<p><a href='https://www.lodop.net/download/CLodop_Setup_for_Win64NT_6.580EN.zip' target='_self' style='color: #1492FF;'>点击这里执行安装</a>，安装后请刷新页面或重新进入。</p>";
	var strHtmUpdate =
		"<p><a href='https://www.lodop.net/download/CLodop_Setup_for_Win64NT_6.580EN.zip' target='_self' style='color: #1492FF;'>点击这里执行升级</a>，升级后请重新进入。</p>";
	var strHtm64_Install =
		"<p><a href='https://www.lodop.net/download/CLodop_Setup_for_Win64NT_6.580EN.zip' target='_self' style='color: #1492FF;'>点击这里执行安装</a>，安装后请刷新页面或重新进入。</p>";
	var strHtm64_Update =
		"<p><a href='https://www.lodop.net/download/CLodop_Setup_for_Win64NT_6.580EN.zip' target='_self' style='color: #1492FF;'>点击这里执行升级</a>，升级后请重新进入。</p>";
	var strHtmFireFox =
		"<p>注意：如曾安装过Lodop旧版附件npActiveXPLugin，请在【工具】->【附加组件】->【扩展】中先卸它</p>";
	var strHtmChrome = "<p>如果此前正常，仅因浏览器升级或重安装而出问题，需重新执行以上安装</p>";
	var strCLodopInstall =
		"<p>CLodop云打印服务（localhost本地）未安装启动！<a href='https://www.lodop.net/download/CLodop_Setup_for_Win64NT_6.580EN.zip' target='_self' style='color: #1492FF;'>点击这里执行安装</a>，安装后请刷新页面。</p>";
	var strCLodopUpdate =
		"<p>CLodop云打印服务需升级！<a href='https://www.lodop.net/download/CLodop_Setup_for_Win64NT_6.580EN.zip' target='_self' style='color: #1492FF;'>点击这里执行升级</a>，升级后请刷新页面。</p>";
	var LODOP;
	try {
		var isIE = (navigator.userAgent.indexOf('MSIE') >= 0) || (navigator.userAgent.indexOf('Trident') >= 0);
		var is64IE = isIE && (navigator.userAgent.indexOf('x64') >= 0);
		//=====如果页面有Lodop就直接使用，没有则新建:==========
		if (oOBJECT != undefined || oEMBED != undefined) {
			if (isIE) LODOP = oOBJECT;
			else LODOP = oEMBED;
		} else if (CreatedOKLodop7766 == null) {
			LODOP = document.createElement("object");
			LODOP.setAttribute("width", 0);
			LODOP.setAttribute("height", 0);
			LODOP.setAttribute("style", "position:absolute;left:0px;top:-100px;width:0px;height:0px;");
			if (isIE) LODOP.setAttribute("classid", "clsid:2105C259-1E0C-4534-8141-A753534CB4CA");
			else LODOP.setAttribute("type", "application/x-print-lodop");
			document.documentElement.appendChild(LODOP);
			CreatedOKLodop7766 = LODOP;
		} else {
			LODOP = CreatedOKLodop7766
		};
		if ((LODOP == null) || (typeof(LODOP.VERSION) == "undefined")) {
			let message = '';
			if (is64IE) {
				message += strHtm64_Install;
			} else {
				message += strHtmInstall;
				if (navigator.userAgent.indexOf('Chrome') >= 0) {
					message += strHtmChrome;
				}
				if (navigator.userAgent.indexOf('Firefox') >= 0) {
					message += strHtmFireFox;
				}
			}
			error('打印控件未安装', message);
			return false;
		};
		if (LODOP.VERSION < "6.2.1.8") {
			let message = '';
			if (is64IE) {
				message += strHtm64_Update;
			} else {
				message += strHtmUpdate;
			}
			error('打印控件需要升级', message);
			return false;
		};
		//===如下空白位置适合调用统一功能(如注册语句、语言选择等):===
		LODOP.SET_LICENSES("", "F4011263D7E17DEAE4642765D883CAF4", "", "B75C0516FF311112B760715A8C6C6D12");
		LODOP.SET_LICENSES("THIRD LICENSE", "", "Zhejiang children's Library Children's supplies Co., Ltd.",
			"73F696D9F4FC45E02954895AF39207A6");
		//===========================================================
		return LODOP;
	} catch (err) {
		error('getLodop出错', err);
	};
};

export default getLodop;